import React from 'react';

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-prev-arrow`}
            style={{ ...style, display: 'block' }} onClick={onClick}
        >
            <i class="fas fa-arrow-left"></i>
        </div>
    );
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className}`}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <i class="fas fa-arrow-right"></i>
        </div>
    );
};

export { NextArrow, PrevArrow };
