import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  height: 90%;
  overflow-y: auto;
  max-width: 700px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ModalFeedback = ({ onClose }) => {
    return (
        <Overlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <div className="p-5">
                    <h3 className="text-black text-[24px] font-[600] mb-5">Top 1 đánh giá cao tại Ninh Thuận</h3>
                    <div className="border rounded-xl overflow-hidden mb-5 shadow-xl">
                        <img src="/anh_tiem/review-googlemaps.png" alt="" className="w-full h-full"/>
                    </div>
                    <div className="border rounded-xl overflow-hidden mb-5 shadow-xl">
                        <img src="/anh_tiem/review.jpg" alt="" className="w-full h-full"/>
                    </div>
                    <div className="border rounded-xl overflow-hidden mb-5 shadow-xl">
                        <img src="/anh_tiem/review-1.png" alt="" className="w-full h-full"/>
                    </div>
                    <div className="border rounded-xl overflow-hidden mb-5 shadow-xl">
                        <img src="/anh_tiem/review-2.png" alt="" className="w-full h-full"/>
                    </div>
                    <div className="border rounded-xl overflow-hidden mb-5 shadow-xl">
                        <img src="/anh_tiem/review-3.png" alt="" className="w-full h-full"/>
                    </div>
                    <div className="border rounded-xl overflow-hidden mb-5 shadow-xl">
                        <img src="/anh_tiem/review-fb-1.png" alt="" className="w-full h-full"/>
                    </div>
                    <div className="border rounded-xl overflow-hidden mb-5 shadow-xl">
                        <img src="/anh_tiem/review-fb-2.png" alt="" className="w-full h-full"/>
                    </div>
                </div>
            </ModalContent>
        </Overlay>
    );
};

export default ModalFeedback;