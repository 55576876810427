import React, { useEffect } from 'react'
import HeaderService from '../components/HeaderService';
import styled from 'styled-components';
import Slider from 'react-slick';
import { truncateText } from '../helpers/helpers';
import { Link } from 'react-router-dom';

const products = [
    {
        title: "Pomade Water 85 NT",
        description: "Sản phẩm tạo kiểu chất lượng cao.",
        image: "/products/DSC00608.JPG",
    },
    {
        title: "Clay 85",
        description: "Sáp vuốt tóc giữ nếp mạnh.",
        image: "/products/DSC00610.JPG",
    },
    {
        title: "Kích Thích Mọc Tóc",
        description: "Giúp tóc mọc nhanh và dày hơn.",
        image: "/products/DSC00613.JPG",
    },
    {
        title: "Nước Súc Miệng Khử Mùi",
        description: "Giữ hơi thở thơm mát cả ngày.",
        image: "/products/DSC00617.JPG",
    },
    {
        title: "Sữa Rửa Mặt HATOMUGI",
        description: "Làm sạch da mặt nhẹ nhàng.",
        image: "/products/DSC00619.JPG",
    },
    {
        title: "Sữa Rửa Mặt ROSETTE",
        description: "Sản phẩm rửa mặt sáng da.",
        image: "/products/DSC00622.JPG",
    },
    {
        title: "Bọt Tạo Kiểu SABALON",
        description: "Tạo kiểu tóc dễ dàng và nhanh chóng.",
        image: "/products/DSC00627.JPG",
    },
    {
        title: "CLAY WAX 85",
        description: "Tác dụng tuyệt đối với tóc dầu.",
        image: "/products/DSC00639.JPG",
    },
    {
        title: "Keo Giữ Nếp Butterfly Shadow",
        description: "Giữ form khi đội mũ bảo hiểm",
        image: "/products/DSC00652.JPG",
    },
    {
        title: "Dầu Gội Thảo Mộc",
        description: "Bảo vệ tóc với thành phần tự nhiên.",
        image: "/products/DSC00660.JPG",
    },
    {
        title: "Sữa Rửa Mặt",
        description: "Làm sạch da mặt hiệu quả.",
        image: "/products/DSC00665.JPG",
    },
    {
        title: "Serum Dưỡng Tóc ZERO",
        description: "Nuôi dưỡng tóc mềm mượt.",
        image: "/products/DSC00669.JPG",
    },
    {
        title: "Dầu Gội Trị Gầu",
        description: "Loại bỏ gàu và chăm sóc da đầu.",
        image: "/products/DSC00675.JPG",
    },
    {
        title: "Kem Đánh Răng Trắng Răng",
        description: "Làm trắng răng hiệu quả.",
        image: "/products/DSC00678.JPG",
    },
    {
        title: "CLAY WAX",
        description: "Sáp vuốt tóc giữ nếp.",
        image: "/products/DSC00681.JPG",
    },
    {
        title: "Máy Cạo Râu",
        description: "Cạo râu dễ dàng và nhanh chóng.",
        image: "/products/DSC00700.JPG",
    },
    {
        title: "Keo Giữ Nếp",
        description: "Giữ nếp tóc cả ngày.",
        image: "/products/DSC00716.JPG",
    }
];

const ServiceWrapper = styled.div`

`;

const ServiceSlider = styled(Slider)`
  .slick-slide {
    padding: 0 10px;
  }
`;

const serviceSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
};

export default function Product() {
    return (
        <div className="mt-[120px]">
            <HeaderService
                titleUpper={`SẢN PHẨM`}
                titleLower={`CHÍNH HÃNG`}
                description={`Với Lee Barbershop bạn sẽ được trải nghiệm những dịch vụ chuyên nghiệp
trong một không gian chuyên nghiệp, thân thiện & gần gũi!`}
            />
            <ServiceWrapper className='container-xl'>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 h-auto">
                    {products.map((service, index) => (
                        <div key={index} className="overflow-hidden rounded-xl border border-[#4b4b4b] relative">
                            <img src={service.image} alt={service.title} className="block w-full h-[200px] object-cover" />
                            <div className="p-[10px] pb-[50px]">
                                <h3 className="font-bold mb-2 text-[18px] uppercase">{service.title}</h3>
                                <p>{truncateText(service.description, 50)}</p>
                                <Link to={"https://www.facebook.com/chuoicattocnam"} target='_blank' className="font-[600] text-[20px] mt-4 text-[#ffa859] block absolute bottom-[10px] left-[10px]">LIÊN HỆ</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </ServiceWrapper>
        </div>
    )
}
