import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

const ContactButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #537761;
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: bold;
  gap:10px;
  z-index: 1000;
  & i{
  color: white;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const ModalButton = styled.a`
  display: inline-block;
  margin: 10px 0px;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    color:white;
    background-color: #0056b3;
    i {
        color: white;
    }
  }
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
        padding: '0',
        overflow: 'hidden',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
};

const ContactModal = () => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <ContactButton onClick={openModal}><i className="fas fa-phone-volume"></i> Liên hệ</ContactButton>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
                <ModalContent>
                    <h2 className="text-[24px] text-black mb-5"><i class="fas fa-id-card text-black"></i> Liên hệ với chúng tôi</h2>
                    <ModalButton href="https://www.facebook.com/luanezreal" target="_blank" className='text-black rounded-xl bg-[#eee]'><i class="fab fa-facebook text-black"></i> Liên hệ Facebook</ModalButton>
                    <ModalButton href="https://zalo.me/0968443368" target="_blank" className='text-black rounded-xl bg-[#eee]'><i class="fas fa-phone-volume text-black"></i> Liên hệ Zalo</ModalButton>
                    <ModalButton href="tel:+0968443368" className='text-black rounded-xl bg-[#eee]'><i class="fas fa-phone-square-alt text-black"></i> 0968.4433.68</ModalButton>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ContactModal;