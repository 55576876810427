import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = ({ openTablePrice, openFeedback }) => {
    const location = useLocation();
    const handleBookingClick = () => {
        window.open('https://booking.easysalon.vn/luanleebarbershop', '_blank');
    }
    return (
        <>
            <nav className='navbar hidden md:flex'>
                <div className='navbar-menu'>
                    <div className='navbar-menu-navigation'>
                        <div className='navbar-brand'>
                            <img src='logo_lee.png' alt='Logo' />
                        </div>
                        <ul className='navbar-menu-list'>
                            <li className={`navbar-item ${location.pathname === '/files' ? 'active' : ''}`}>
                                <Link to="/"><i className="fa fa-home"></i>Trang chủ</Link>
                            </li>
                            <li className={`navbar-item ${location.pathname === '/create-file' ? 'active' : ''}`} onClick={handleBookingClick}>
                                <i className="fa fa-calendar-alt"></i> Đặt lịch
                            </li>
                            <li className={`navbar-item ${location.pathname === '/contents' ? 'active' : ''}`}>
                                <Link to="/san-pham"><i className="fa fa-shopping-basket"></i>Sản phẩm</Link>
                            </li>
                            <li className={`navbar-item ${location.pathname === '/contents' ? 'active' : ''}`} onClick={openFeedback}>
                                <i className="fas fa-comments"></i>Feedback
                            </li>
                            <li className={`navbar-item ${location.pathname === '/contents' ? 'active' : ''}`} onClick={openTablePrice}>
                                <i class="fas fa-money-bill-wave"></i>Bảng giá
                            </li>
                        </ul>
                        {/* <div className='navbar-user'>
                        <button className='navbar-user-cart'><i class="fas fa-shopping-cart"></i></button>
                        <button className='navbar-user-login'><i class="fas fa-users"></i>Thành viên</button>
                    </div> */}
                    </div>
                </div>
            </nav>
        </>

    );
};

export default Navbar;