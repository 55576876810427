import React from 'react';
import styled from 'styled-components';

// Define styled components
const Container = styled.div`
  text-align: center;
  margin: 50px 0;
`;

const MainHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const TitleUpper = styled.h2`
  color: #ffa859; /* Tomato color */
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
  letter-spacing: 2px;
`;

const TitleLower = styled.h2`
  color: #ffffff;
  margin: 0;
  position: relative;
  font-weight: bold;
  letter-spacing: 1x;
  &::after {
    content: '';
    display: block;
    width: 40%;
    height: 3px;
    background: #fff;
    margin: 10px auto 0;
  }
`;

const Description = styled.p`
line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
`;

const HeaderService = ({ titleUpper, titleLower, description, onlyLine = false }) => {
    return (
        <Container>
            <MainHeader>
                {onlyLine ?
                    <div className='flex gap-2'>
                        <TitleUpper className='text-[2rem] md:text-[2.5rem]'>{titleUpper}</TitleUpper>
                        <TitleLower className='text-[2rem] md:text-[2.5rem]'>{titleLower}</TitleLower>
                    </div>
                    : <>
                        <TitleUpper className='text-[2rem] md:text-[2.5rem]'>{titleUpper}</TitleUpper>
                        <TitleLower className='text-[2rem] md:text-[2.5rem]'>{titleLower}</TitleLower>
                    </>}

            </MainHeader>
            <Description className='text-[0.8rem] md:text-[1rem]'>
                {description}
            </Description>
        </Container>
    );
};

export default HeaderService;