import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Product from './pages/Product'
import DefaultLayout from './layouts/DefaultLayout'
import ScrollToTop from './components/ScrollToTop'
import ContactModal from './components/ContactModal'
import ImageModal from './components/ImageModal'
import ModalFeedback from './components/ModalFeedback'

export default function App() {
  const [isModalOpenFeedback, setIsModalOpenFeedback] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModalFeedback = () => {
    setIsModalOpenFeedback(true);
  };

  const handleCloseModalFeedback = () => {
    setIsModalOpenFeedback(false);
  };

  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal} handleOpenModalFeedback={handleOpenModalFeedback} handleCloseModalFeedback={handleCloseModalFeedback} />} />
        <Route path="san-pham" element={<DefaultLayout handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal} handleOpenModalFeedback={handleOpenModalFeedback} handleCloseModalFeedback={handleCloseModalFeedback} />}>
          <Route index element={<Product />} />
        </Route>
      </Routes>
      <ContactModal />
      {isModalOpen && (
        <ImageModal
          src="/anh_tiem/1I3S4V445_9UMIH6.JPG"
          alt="Description of image"
          onClose={handleCloseModal}
        />
      )}
      {isModalOpenFeedback && (
        <ModalFeedback
          onClose={handleCloseModalFeedback}
        />
      )}
    </div>
  )
}
