import React, { useRef, useState } from 'react';
import Navbar from '../components/Navbar';
import styled, { keyframes } from 'styled-components';
import Slider from 'react-slick';
import ContactModal from '../components/ContactModal';
import HeaderService from '../components/HeaderService';
import { truncateText } from '../helpers/helpers';
import ImageModal from '../components/ImageModal';
import Footer from '../components/Footer';
import ModalFeedback from '../components/ModalFeedback';
import { Link } from 'react-router-dom';
import { NextArrow, PrevArrow } from '../components/CustomArrow';

const slides = [
    {
        headText: "Phong Cách Hiện Đại",
        image: '/anh_tiem/z5673841421652_41d3ee32e6e5eec13b472b057c663040.jpg',
        message: 'Trải nghiệm những kiểu tóc nam hiện đại và tinh tế tại Lee Barbershop',
    },
    {
        headText: "Chăm Sóc Tận Tình",
        image: '/anh_tiem/z5673841407054_2cd8fa10bf59e0789a55cf3b5b6c028d.jpg',
        message: 'Đội ngũ nhân viên chuyên nghiệp, tận tâm chăm sóc và tư vấn kiểu tóc phù hợp nhất cho bạn',
    },
    {
        headText: "Không Gian Thoải Mái",
        image: '/anh_tiem/z5673841374333_ba6c3af9c8c78f15d5849cce974686c9.jpg',
        message: 'Thư giãn trong không gian thoải mái và thân thiện của Lee Barbershop, nơi giúp bạn quên đi mọi căng thẳng',
    },
    {
        headText: "Phong Cách Lịch Lãm",
        image: '/anh_tiem/z5673841355415_91c83e9721a46b6cd519f7e462fcf0a8.jpg',
        message: 'Đến Lee Barbershop, bạn sẽ được tạo kiểu tóc lịch lãm, chuẩn men và đầy cuốn hút',
    },
];

const services = [
    {
        title: "Chăm sóc da",
        description: "Chúng tôi cung cấp các liệu trình chăm sóc da chuyên nghiệp và sử dụng các sản phẩm chất lượng cao.",
        image: "/anh_tiem/DSC00971.JPG",
    },
    {
        title: "Tẩy & nhuộm tóc",
        description: "Chúng tôi sử dụng các sản phẩm an toàn và kỹ thuật tẩy nhuộm tiên tiến để đảm bảo mái tóc của bạn luôn khỏe và đẹp.",
        image: "/anh_tiem/DSC01000.JPG",
    },
    {
        title: "Cắt xả",
        description: "Dịch vụ cắt xả tóc chuyên nghiệp, mang lại cho bạn kiểu tóc phù hợp và ấn tượng.",
        image: "/anh_tiem/DSC01009.JPG",
    },
    {
        title: "Gội dưỡng sinh",
        description: "Chúng tôi sử dụng các sản phẩm gội dưỡng cao cấp giúp tóc và da đầu bạn luôn khỏe mạnh và thư giãn.",
        image: "/anh_tiem/z5673841317030_2f44dddf3c501cab5e406d4be3e32bfb.jpg",
    }
];

const barbers = [
    {
        title: "Luận",
        position: "Barber",
        description: "Mang đến kiểu tóc đỉnh cao",
        image: "/anh_barber/Selected/luan.jpg",
    },
    {
        title: "Triều",
        position: "Barber",
        description: "Tạo kiểu tóc với đam mê",
        image: "/anh_barber/Selected/trieu.jpg",
    },
    {
        title: "Trung",
        position: "Barber",
        description: "Phong cách và chất lượng",
        image: "/anh_barber/Selected/trung.jpg",
    },
    {
        title: "Quỳnh",
        position: "Thu ngân, gội đầu, ráy tai",
        description: "Dịch vụ hoàn hảo, chăm sóc tận tình",
        image: "/anh_barber/Selected/hien.jpg",
    },
    {
        title: "Đạt",
        position: "Barber",
        description: "Sáng tạo trong từng đường kéo",
        image: "/anh_barber/Selected/dat.jpg",
    },
    {
        title: "Nhân",
        position: "Barber",
        description: "Chất lượng từ những chi tiết nhỏ",
        image: "/anh_barber/Selected/nhan.jpg",
    },
    {
        title: "Hoàng",
        position: "Barber",
        description: "Mang lại vẻ đẹp nam tính",
        image: "/anh_barber/Selected/hoan.JPG",
    },
    {
        title: "Phát",
        position: "Barber",
        description: "Tỉ mỉ và chính xác",
        image: "/anh_barber/Selected/phat.jpg",
    },
    {
        title: "My",
        position: "Thu ngân, gội đầu, ráy tai",
        description: "Thư giãn và chăm sóc tốt nhất",
        image: "/anh_barber/Selected/my.JPG",
    }
];

const products = [
    {
        title: "Pomade Water 85 NT",
        description: "Sản phẩm tạo kiểu chất lượng cao.",
        image: "/products/DSC00608.JPG",
    },
    {
        title: "Clay 85",
        description: "Sáp vuốt tóc giữ nếp mạnh.",
        image: "/products/DSC00610.JPG",
    },
    {
        title: "Kích Thích Mọc Tóc",
        description: "Giúp tóc mọc nhanh và dày hơn.",
        image: "/products/DSC00613.JPG",
    },
    {
        title: "Nước Súc Miệng Khử Mùi",
        description: "Giữ hơi thở thơm mát cả ngày.",
        image: "/products/DSC00617.JPG",
    },
    {
        title: "Sữa Rửa Mặt HATOMUGI",
        description: "Làm sạch da mặt nhẹ nhàng.",
        image: "/products/DSC00619.JPG",
    },
    {
        title: "Sữa Rửa Mặt ROSETTE",
        description: "Sản phẩm rửa mặt sáng da.",
        image: "/products/DSC00622.JPG",
    },
    {
        title: "Bọt Tạo Kiểu SABALON",
        description: "Tạo kiểu tóc dễ dàng và nhanh chóng.",
        image: "/products/DSC00627.JPG",
    },
    {
        title: "CLAY WAX 85",
        description: "Tác dụng tuyệt đối với tóc dầu.",
        image: "/products/DSC00639.JPG",
    },
    {
        title: "Keo Giữ Nếp Butterfly Shadow",
        description: "Giữ form khi đội mũ bảo hiểm",
        image: "/products/DSC00652.JPG",
    },
    {
        title: "Dầu Gội Thảo Mộc",
        description: "Bảo vệ tóc với thành phần tự nhiên.",
        image: "/products/DSC00660.JPG",
    },
    {
        title: "Sữa Rửa Mặt",
        description: "Làm sạch da mặt hiệu quả.",
        image: "/products/DSC00665.JPG",
    },
    {
        title: "Serum Dưỡng Tóc ZERO",
        description: "Nuôi dưỡng tóc mềm mượt.",
        image: "/products/DSC00669.JPG",
    },
    {
        title: "Dầu Gội Trị Gầu",
        description: "Loại bỏ gàu và chăm sóc da đầu.",
        image: "/products/DSC00675.JPG",
    },
    {
        title: "Kem Đánh Răng Trắng Răng",
        description: "Làm trắng răng hiệu quả.",
        image: "/products/DSC00678.JPG",
    },
    {
        title: "CLAY WAX",
        description: "Sáp vuốt tóc giữ nếp.",
        image: "/products/DSC00681.JPG",
    },
    {
        title: "Máy Cạo Râu",
        description: "Cạo râu dễ dàng và nhanh chóng.",
        image: "/products/DSC00700.JPG",
    },
    {
        title: "Keo Giữ Nếp",
        description: "Giữ nếp tóc cả ngày.",
        image: "/products/DSC00716.JPG",
    }
];

const BannerWrapper = styled.div`
  width: 100%;
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    pointer-events: none; /* Đảm bảo lớp nền không ảnh hưởng đến các tương tác */
  }
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const MessageWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.active {
    opacity: 1;
  }
  cursor: pointer;
`;

const Message = styled.div`
  margin: 0 auto;
`;

const HeadText = styled.div`
  font-weight: bold;
  color: #fff;
  letter-spacing: 6px;
  font-family: "Fjalla One", sans-serif;
  opacity: 1;
  color:transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white; /* Viền trắng cho chữ */
    text-stroke: 1px white; /* Viền trắng cho chữ (dành cho trình duyệt hỗ trợ) */
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &.prev {
  background-color: transparent;
    left: 100px;
    font-size: 40px;
  }

  &.next {
    background-color: transparent;
    right: 100px;
    font-size: 40px;
  }
`;

const fadeIn = keyframes`
  0%, 100% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
`;

const ButtonBooking = styled.button`
  margin-top: 30px;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  border-radius: 10px;
  color: transparent;
  font-weight: bold;
  border: 2px solid #fff; /* Thêm viền */
  cursor: pointer;
  animation: ${fadeIn} 4s ease-in-out infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  &:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
`;


const ButtonCustom = styled.button`
overflow: hidden;
  padding: 20px 40px;
  margin-top: 30px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border: 1px solid rgba(255, 139, 0, 1);
  color: white;
  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
  }
  }
`;

const LinkCustom = styled(Link)`
overflow: hidden;
  padding: 20px 40px;
  margin-top: 30px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border: 1px solid rgba(255, 139, 0, 1);
  color: white;
  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
  }
  }
`;


const AboutServiceItem = styled.div`
  position: relative;
  
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & h3 {
    text-align: center;
    width: 100%;
    font-weight: 500;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Bangers", sans-serif;
    letter-spacing: 3px;
  }
`;



const ServiceWrapper = styled.div`

`;

const ServiceSlider = styled(Slider)`
  .slick-slide {
    padding: 0 10px;
  }
`;

const ServiceItem = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
   & div {
    width: 100%;
   position: absolute;
    top: 60%;
    transform: translateY(-50%);
    text-align: center;
   }
  & h3 {
   width: 100%;
    font-weight: 500;
    font-size: 30px;
    font-family: "Bangers", sans-serif;
    letter-spacing: 3px;
    color: white;
    margin-bottom: 0px;
  transition: all 0.5s ease-in-out;

  }
  & h5 {
   width: 100%;
    font-weight: 500;
    font-size: 16px;
    font-family: "Bangers", sans-serif;
    letter-spacing: 1px;
    color: white;
    margin-bottom: 0px;
  transition: all 0.5s ease-in-out;

  }
    & p {
   opacity: 0;
   visibility: hidden;
  transition: all 0.5s ease-in-out;

  }
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }

  &:hover {
  & p{
  opacity: 1;
  visibility: visible;
   line-height: 1.5;
  }
   & h3{
   margin-bottom: 10px;}
}
`;

const Home = ({ handleOpenModal, handleOpenModalFeedback }) => {
    const sliderRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);
    
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        beforeChange: (current, next) => setSlideIndex(next),
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />

    };

    const serviceSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    const handleButtonClick = () => {
        window.open('https://www.youtube.com/@leebarbershop', '_blank');

    }

    const handleBookingClick = () => {
        window.open('https://booking.easysalon.vn/luanleebarbershop', '_blank');
    }
    return (
        <div>
            <div className='relative'>
                <Navbar openTablePrice={handleOpenModal} openFeedback={handleOpenModalFeedback} />
                <BannerWrapper>
                    <Slider ref={sliderRef} {...sliderSettings}>
                        {slides.map((slide, index) => (
                            <Slide className='h-[600px] md:h-screen block' key={index}>
                                <SlideImage src={slide.image} alt={`Slide ${index + 1}`} />
                                <MessageWrapper className={`mt-[50px] lg:mt-[40px] ${(index === slideIndex ? 'active' : '')}`}>
                                    <HeadText className="mb-[20px] md:mb[50px] leading-normal text-4xl md:text-6xl lg:text-8xl text-center">{slide.headText}</HeadText>
                                    <Message className='md:w-[60%] text-[14px] md:text-[18px]'>
                                        {slide.message}
                                    </Message>
                                    <ButtonBooking onClick={handleBookingClick} className='py-[10px] px-[20px] md:py-[20px] md:px-[40px] text-[16px] lg:text-[24px] xl:text-[38px]'><i className="fas fa-calendar-day"></i> Đặt Lịch Ngay</ButtonBooking>
                                </MessageWrapper>
                            </Slide>
                        ))}
                    </Slider>
                </BannerWrapper>
            </div>
            <div className="container-xl">
            <div className="grid md:grid-cols-3 p-5 md:px-0 gap-5">
                <AboutServiceItem className='h-[210px] md:h-[330px] border rounded-2xl mb-3 md:mb-0 overflow-hidden'>
                    <img src="/anh_tiem/IMG_3377.JPG" />
                    <h3 className="text-white text-[30px] lg:text-[50px]">SẢN PHẨM</h3>
                </AboutServiceItem>
                <AboutServiceItem className='h-[210px] md:h-[330px] border rounded-2xl mb-3 md:mb-0 overflow-hidden'>
                    <img src="/anh_tiem/DSC00989.JPG" />
                    <h3 className="text-white text-[30px] lg:text-[50px]">DỊCH VỤ</h3>
                </AboutServiceItem>
                <AboutServiceItem className='h-[210px] md:h-[330px] border rounded-2xl mb-3 md:mb-0 overflow-hidden'>
                    <img src="/anh_tiem/DSC00872.JPG" />
                    <h3 className="text-white text-[30px] lg:text-[50px]">ĐÀO TẠO</h3>
                </AboutServiceItem>
            </div>
            </div>
            <HeaderService titleUpper={`TRÃI NGHIỆM`} titleLower={`DỊCH VỤ`} description={`Với Lee Barbershop bạn sẽ được trải nghiệm những dịch vụ chuyên nghiệp
trong một không gian chuyên nghiệp, thân thiện & gần gũi!`} onlyLine={true} />
            <ServiceWrapper className='container-xl'>
                <ServiceSlider {...serviceSliderSettings}>
                    {services.map((service, index) => (
                        <ServiceItem key={index}>
                            <img src={service.image} alt={service.title} />
                            <div>
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                            </div>
                        </ServiceItem>
                    ))}
                </ServiceSlider>
            </ServiceWrapper>
            <div className='container-xl'>
                <ButtonCustom className='mx-auto block rounded-full text-[14px] md:text-[18px]' onClick={handleOpenModal}><i class="fas fa-hand-point-right"></i> Bảng Giá Dịch Vụ <i class="fas fa-hand-point-left"></i></ButtonCustom>
                <HeaderService
                    titleUpper={`ĐỘI NGŨ`}
                    titleLower={`BARBER`}
                    description={`Lee Barbershop mang đến cho bạn trải nghiệm dịch vụ chuyên nghiệp,
không gian thoải mái và đội ngũ thợ cắt tóc tận tâm, thân thiện!`}
                    onlyLine={true}
                />
            </div>
            <ServiceWrapper className='container-xl'>
                <ServiceSlider {...serviceSliderSettings}>
                    {barbers.map((service, index) => (
                        <ServiceItem key={index} className='object-top md:object-center'>
                            <img src={service.image} alt={service.title} />
                            <div>
                                <h3>{service.title}</h3>
                                <h5>({service.position})</h5>
                                <p>{service.description}</p>
                            </div>
                        </ServiceItem>
                    ))}
                </ServiceSlider>
            </ServiceWrapper>
            <div className="h-[500px] mt-[50px] relative">
                <div className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                    <HeaderService
                        titleUpper={`CÔNG VIỆC`}
                        titleLower={`CỦA CHÚNG TÔI`}
                        description={`Tại Lee Barbershop, bạn sẽ được trải nghiệm dịch vụ chuyên nghiệp trong một không gian
thân thiện và gần gũi. Xem thêm các video dịch vụ của chúng tôi tại kênh YouTube!`}
                    />
                    <ButtonCustom className="mx-auto block rounded-full border text-[14px] md:text-[18px]" onClick={handleButtonClick}><i class="fas fa-hand-point-right"></i> Xem Thêm Tại Đây <i class="fas fa-hand-point-left"></i></ButtonCustom>
                </div>
                <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
                <img src="/anh_tiem/namecard.jpg" alt="" className="block h-full w-full object-cover" />
            </div>

            <HeaderService
                titleUpper={`HOẠT ĐỘNG`}
                titleLower={`THIỆN NGUYỆN`}
                description={`Tham gia cùng Lee Barbershop để trải nghiệm các dịch vụ chuyên nghiệp
trong một không gian thân thiện và gần gũi, đồng thời góp phần vào các hoạt động
thiện nguyện ý nghĩa!`}
            />

            <div className="container-xl">
                <img src="/anh_tiem/Screenshot 2024-07-28 at 15.17.42.png" alt="" className="block object-fill" />
            </div>

            <HeaderService
                titleUpper={`SẢN PHẨM`}
                titleLower={`CHÍNH HÃNG`}
                description={`Với Lee Barbershop bạn sẽ được trải nghiệm những dịch vụ chuyên nghiệp
trong một không gian chuyên nghiệp, thân thiện & gần gũi!`}
            />
            <ServiceWrapper className='container-xl'>
                <ServiceSlider {...serviceSliderSettings}>
                    {products.map((service, index) => (
                        <div key={index} className="overflow-hidden rounded-xl border border-[#4b4b4b] relative">
                            <img src={service.image} alt={service.title} className="block w-full h-[200px] object-cover" />
                            <div className="p-[10px] pb-[50px]">
                                <h3 className="font-bold mb-2 text-[18px] uppercase">{truncateText(service.title, 20)}</h3>
                                <p>{truncateText(service.description, 26)}</p>
                                <Link to={"https://www.facebook.com/chuoicattocnam"} target='_blank' className="font-[600] text-[20px] mt-4 text-[#ffa859] block absolute bottom-[10px] left-[10px]">LIÊN HỆ</Link>
                            </div>
                        </div>
                    ))}
                </ServiceSlider>
            </ServiceWrapper>
            <div className="flex justify-center">
                <LinkCustom to={"/san-pham"} className='block rounded-full text-[14px] md:text-[18px]'><i class="fas fa-hand-point-right"></i> Xem Thêm Tất Cả Sản Phẩm <i class="fas fa-hand-point-left"></i></LinkCustom>
            </div>
            <ContactModal />
            <Footer />
        </div>
    );
};

export default Home;