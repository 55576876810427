import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const DefaultLayout = ({ handleOpenModal, handleOpenModalFeedback }) => {
    return (
        <div>
            <Navbar openTablePrice={handleOpenModal} openFeedback={handleOpenModalFeedback} />
            <div>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default DefaultLayout;